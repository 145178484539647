
// Utils
// Components
import VImage from '@@/common/components/ui/images/VImage';

export default {
    name: 'PartnersBlock',

    components: {
        VImage,
    },

    props: {
        partners: {
            type: Array,
            default: () => [],
        },

        firstScreenBlock: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        getIsFileExtensionSvg(url) {
            const ex = url.match(/\.[^.]+$/g);
            return (ex[0]?.substring(1) || '') === 'svg';
        },
    },
};
