// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PartnersBlock_GS9E7{background-color:#17181c;border-radius:1.6rem;display:flex;gap:9rem;max-width:100%;overflow:hidden;position:relative;width:100%}@media(max-width:1279.98px){.PartnersBlock_GS9E7{gap:4rem}}.wrapper_DDnZL{align-items:center;animation:scroll_wIs2M 10s linear infinite;box-sizing:border-box;display:flex;gap:9rem;height:100%;padding:3.2rem 0;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}@media(max-width:1279.98px){.wrapper_DDnZL{animation:scroll-tablet_RRIPP 10s linear infinite;gap:4rem}}.item_glI-Y{align-items:center;display:flex;height:100%;transition:opacity .3s ease}@media(-ms-high-contrast:active),(-ms-high-contrast:none),(hover){.item_glI-Y:hover{opacity:.85}}.logo_K1Xbq{height:3.4rem;margin-right:1.6rem;width:auto}@media(max-width:1279.98px){.logo_K1Xbq{height:2.8rem;margin-right:1.3rem}}.logo_K1Xbq>img{width:auto}.text_QX7Tg{color:#fff;font-size:1.4rem;font-weight:400;letter-spacing:-.056rem;line-height:1.68rem}@media(max-width:1279.98px){.text_QX7Tg{font-size:1.2rem;font-weight:400;letter-spacing:-.048rem;line-height:1.44rem}}@keyframes scroll_wIs2M{0%{transform:translateX(0)}to{transform:translateX(calc(-100% - 9rem))}}@keyframes scroll-tablet_RRIPP{0%{transform:translateX(0)}to{transform:translateX(calc(-100% - 4rem))}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-tablet": "5.6rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"PartnersBlock": "PartnersBlock_GS9E7",
	"wrapper": "wrapper_DDnZL",
	"scroll": "scroll_wIs2M",
	"scroll-tablet": "scroll-tablet_RRIPP",
	"item": "item_glI-Y",
	"logo": "logo_K1Xbq",
	"text": "text_QX7Tg"
};
module.exports = ___CSS_LOADER_EXPORT___;
